import { io } from 'socket.io-client'

const baseURL = process.env.REACT_APP_BACKEND_SERVER_URL || ''

export const createSocket = (token: string, groupId: string) => {
    const socketio = io(baseURL, {
        extraHeaders: {
            Authorization: `Bearer ${token}`,
            group_id: groupId || '',
        },
    })

    return socketio
}
