import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../data/store'
import { ImplementData, SoftwareVersions } from '../data/types'
import { useNetworkManager } from '../network/networkManager'
import { ConfirmationPopup } from '../components/misc/popup'
import { EquipmentTiles } from '../components/equipment/equipmentTiles'
import { faTrailer } from '@fortawesome/free-solid-svg-icons'

function Implements() {
    const [_implements, setImplements] = useState<ImplementData[]>([])
    const _implementData = useSelector((state: RootState) => state._implements.data)
    const { _implements: _implementApi } = useNetworkManager()

    const [isDialogueVisible, setIsDialogueVisible] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [_implementIdToDelete, setImplementIdToDelete] = useState<string | null>(null)

    useEffect(() => {
        setImplements(_implementData)
    }, [_implementData])

    const handleCreateImplement = async (name: string, description: string) => {
        await _implementApi.addImplement(name, description)
    }

    const handleDeleteImplement = async (_implement_id: string) => {
        setImplementIdToDelete(_implement_id)
        setIsDialogueVisible(true)
    }

    const deleteHandler = async (confirmation: boolean) => {
        if (confirmation && _implementIdToDelete) {
            setLoading(true)
            await _implementApi.deleteImplement(_implementIdToDelete)
            setLoading(false)
            setImplementIdToDelete(null)
        }
        setIsDialogueVisible(false)
    }

    const handleEditImplement = async (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => {
        _implementApi.editImplement(id, name, description, softwareVersions)
    }

    return (
        <div className="equipments-container-wrapper">
            <div className="equipments-container">
                {isDialogueVisible && (
                    <ConfirmationPopup
                        ChildComponent={<div>Are you sure you want to delete this _implement?</div>}
                        callback={deleteHandler}
                        loading={loading}
                    />
                )}
                <EquipmentTiles
                    typeTitle={'Implement'}
                    typeIcon={faTrailer}
                    equipment={_implements as ImplementData[]}
                    onCreate={handleCreateImplement}
                    onEdit={handleEditImplement}
                    onDelete={handleDeleteImplement}
                />
            </div>
        </div>
    )
}

export default Implements
