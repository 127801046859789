import React, { ReactElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'

import './popup.css'

interface PopupProps {
    ChildComponent: ReactElement
    callback?: () => void
}

const Popup: React.FC<PopupProps> = ({ ChildComponent, callback }) => {
    const handleInputChange = () => {
        callback && callback()
    }

    return (
        <div className="popup-parent">
            <div className="popup-wrapper">
                <FontAwesomeIcon
                    icon={faTimes}
                    onClick={handleInputChange}
                    className="close-icon"
                />
                <div>{ChildComponent}</div>
            </div>
        </div>
    )
}

interface ConfirmationPopupProps {
    ChildComponent: React.ReactElement
    callback?: (confirmation: boolean) => void
    loading?: boolean
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
    ChildComponent,
    callback,
    loading,
}) => {
    const handleInputChange = (confirmation: boolean) => {
        if (callback) {
            callback(confirmation)
        }
    }

    return (
        <div className="popup-parent">
            <div className="popup-wrapper">
                <div>{ChildComponent}</div>
                <div className="button-group">
                    <button onClick={() => handleInputChange(true)} className="form-button">
                        {!loading ? (
                            <>
                                <span style={{ marginRight: '10px' }}>Yes</span>{' '}
                                <FontAwesomeIcon icon={faCheck} className="close-icon" />
                            </>
                        ) : (
                            <FontAwesomeIcon icon={faSpinner} spin className="loading-icon" />
                        )}
                    </button>
                    <button onClick={() => handleInputChange(false)} className="form-button">
                        <span style={{ marginRight: '10px' }}>No</span>
                        <FontAwesomeIcon icon={faTimes} className="close-icon" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export { Popup, ConfirmationPopup }
