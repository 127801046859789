import '../recorder.css'

import { SessionData } from '../../../data/types'
import { toast } from 'react-toastify'
import { Toolbar } from '../../toolbar/toolbar'
import SimpleSnapshotManager from '../snapshotManagers/simpleSnapshotManager'
import { useLogEvents } from '../logging/logEvents'
import { capitalizeFirstLetter } from '../../utils/misc'

interface SimpleViewProps {
    sessionId: string
    isSessionActive: boolean
    isSessionOwner: boolean
    activeSession: SessionData | null
    isSimpleViewEnabled: boolean
    handleSimpleViewSetEnabled: (enabled: boolean) => void
}

const SimpleView = ({
    sessionId,
    isSessionActive,
    isSessionOwner,
    activeSession,
    isSimpleViewEnabled,
    handleSimpleViewSetEnabled,
}: SimpleViewProps) => {
    const { logData } = useLogEvents()

    const notifyEventReported = (classification: string) =>
        toast.success(`${classification} reported!`, {
            position: 'top-right',
            autoClose: 2000, // time in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    const logDataAndNotify = async (
        initiationTimestamp: string,
        data: Record<string, any>,
        classification: string
    ) => {
        const success = await logData(sessionId, initiationTimestamp, data, classification)
        if (success) {
            notifyEventReported(capitalizeFirstLetter(classification))
        }
        return success
    }

    return (
        <div className="field-recorder-wrapper">
            <div className="field-recorder">
                <div className="recorder-prev-session-wrapper">
                    <Toolbar
                        tools={[
                            {
                                label: 'Simple View',
                                data: {
                                    enabled: isSimpleViewEnabled,
                                    setEnabled: handleSimpleViewSetEnabled,
                                },
                            },
                        ]}
                    />
                </div>
                <div className="bug-reporter-parent-wrapper">
                    <div className="bug-reporter">
                        {isSessionActive && (
                            <SimpleSnapshotManager onReportEvent={logDataAndNotify} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SimpleView
