import { Fragment, useRef, useState } from 'react'
import { SessionData, SnapshotData } from '../../data/types'
import { capitalizeFirstLetter, FormatDate, TimestampDelta } from '../utils/misc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faLink, faEdit, faCopy, faCamera } from '@fortawesome/free-solid-svg-icons'
import { MutableRefObject } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { renderDescription } from './eventRenderer'
import { RenderImages } from './imageRenderer'
import LogEventForm from '../recorder/events/logEventForm'
import { useLogEvents } from '../recorder/logging/logEvents'

interface TileViewProps {
    session: SessionData
    snapshot: SnapshotData
    previousTimestamp?: string | null
    handleDeleteSnapshot?: (snapshotId: string) => void
    handleDuplicateSnapshot?: (snapshotId: string) => void
    isAttributions?: boolean
    isUtc: boolean | undefined
    scrollToEvent?: string
    snapshotRefs?: MutableRefObject<{ [key: string]: HTMLDivElement | null }>
    isDescending: boolean
    isToolbarEnabled?: boolean
    handleFileUpload?: (snapshotId: string, file: File | null) => Promise<boolean>
    deleteImage?: (snapshotId: string, imageId: string) => Promise<boolean>
}

export const TileView = ({
    session,
    snapshot,
    previousTimestamp,
    handleDeleteSnapshot,
    handleDuplicateSnapshot,
    isAttributions = true,
    isUtc,
    scrollToEvent,
    snapshotRefs,
    isDescending,
    isToolbarEnabled = true,
    handleFileUpload,
    deleteImage,
}: TileViewProps) => {
    const location = useLocation()
    const [showEditForm, setShowEditForm] = useState(false)
    const [imageUploading, setImageUploading] = useState<boolean>(false)

    const type = snapshot.description ? Object.keys(snapshot.description)[0] : ''
    const uniqueId = `file-upload-${Math.random().toString(36).substr(2, 9)}`

    // Create a ref for the file input
    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const notifyStopSuccess = () =>
        toast.success('Event Link Copied', {
            position: 'top-right',
            autoClose: 2000,
            style: { fontSize: '18px' },
        })
    const notifyEventUpdated = (classification: string) =>
        toast.success(`${classification} updated!`, {
            position: 'top-right',
            autoClose: 2000,
            style: { fontSize: '18px' },
        })

    const handleEventLink = () => {
        const params = new URLSearchParams(location.search)
        params.set('scrollToEvent', snapshot.id)
        const newUrl = `${window.location.origin}${location.pathname}?${params.toString()}`
        navigator.clipboard.writeText(newUrl).then(() => notifyStopSuccess())
    }

    const toggleEditForm = () => {
        setShowEditForm(!showEditForm)
    }

    const { editSnapshot } = useLogEvents()

    const editSnapshotAndNotify = async (
        initiationTimestamp: string,
        data: Record<string, any>,
        classification: string
    ) => {
        const success = await editSnapshot(session.id, snapshot, data)
        if (success) {
            setShowEditForm(false)
            const classification = Object.keys(data)[0] || type
            notifyEventUpdated(capitalizeFirstLetter(classification))
        }
        return success
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null
        if (selectedFile) {
            setImageUploading(true)
            if (handleFileUpload) {
                await handleFileUpload(snapshot.id, selectedFile)
            }
            setImageUploading(false)
        }
        // Reset the file input after upload
        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    const deleteImageHelper = async (imageId: string) => {
        try {
            if (deleteImage) await deleteImage(snapshot.id, imageId)
        } catch (error) {
            console.error('Error deleting image:', error)
        }
    }

    const creator =
        snapshot?.creator?.given_name && snapshot?.creator?.family_name
            ? `${snapshot?.creator?.given_name} ${snapshot?.creator?.family_name}`
            : 'N/A'

    const renderControls = () => {
        return (
            <div className="controls">
                {type !== 'update' && (
                    <>
                        <button className="link-button" title="Upload Image">
                            <label htmlFor={uniqueId} className="file-upload-label">
                                <FontAwesomeIcon icon={faCamera} />
                            </label>
                        </button>
                        <button
                            className="link-button"
                            title="Edit Snapshot"
                            onClick={toggleEditForm}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                        {handleDuplicateSnapshot && (
                            <button
                                className="link-button"
                                title="Duplicate Snapshot"
                                onClick={() => handleDuplicateSnapshot(snapshot.id)}
                            >
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        )}
                    </>
                )}
                <button className="link-button" title="Copy Link" onClick={handleEventLink}>
                    <FontAwesomeIcon icon={faLink} />
                </button>
                {type !== 'update' && handleDeleteSnapshot && (
                    <button
                        className="delete-button"
                        title="Delete Entry"
                        onClick={() => handleDeleteSnapshot(snapshot.id)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
            </div>
        )
    }

    return (
        <Fragment key={snapshot.id}>
            {!isDescending && previousTimestamp && (
                <TimestampDelta
                    previousTimestamp={previousTimestamp}
                    currentTimestamp={snapshot.initiationTimestamp}
                    limit={300000}
                />
            )}
            <div
                className={`snapshot-tile ${snapshot.id === scrollToEvent ? 'selected' : ''}`}
                ref={snapshotRefs ? (el) => (snapshotRefs.current[snapshot.id] = el) : null}
            >
                <div className="wrapper">
                    <div className="toolbar-small-screen">
                        {isToolbarEnabled && renderControls()}
                    </div>
                    <div className="content">
                        {snapshot.description && renderDescription(snapshot.description)}
                    </div>
                    <div className="images">
                        <RenderImages
                            images={snapshot.images}
                            deleteImage={deleteImageHelper}
                            imageUploading={imageUploading}
                        />
                    </div>
                    <div className="header">
                        <div className="timestamp">
                            <div className="toolbar-large-screen">
                                {isToolbarEnabled && renderControls()}
                            </div>
                            {isAttributions && <div>Logged by: {creator}</div>}
                            <div>{FormatDate(snapshot.initiationTimestamp, isUtc)}</div>
                        </div>
                    </div>
                </div>
                {showEditForm && (type === 'event' || type === 'bug') && (
                    <LogEventForm
                        onReportEvent={editSnapshotAndNotify}
                        defaultOptions={snapshot.description}
                        onClose={toggleEditForm}
                        isEdit={true}
                    />
                )}
            </div>
            {isDescending && previousTimestamp && (
                <TimestampDelta
                    previousTimestamp={previousTimestamp}
                    currentTimestamp={snapshot.initiationTimestamp}
                    limit={300000}
                />
            )}

            <input
                id={uniqueId}
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef} // Attach the ref to the input
                style={{ display: 'none' }}
            />
        </Fragment>
    )
}
