import { useState } from 'react'
import { ImplementData, MachineData, SoftwareVersions } from '../../data/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faEdit, faExpandAlt, faMinimize } from '@fortawesome/free-solid-svg-icons'
import { CreationForm } from './equipmentForm'
import './equipment.css'

interface EquipmentTilesProps {
    typeTitle: string
    typeIcon: IconDefinition
    equipment: MachineData[] | ImplementData[]
    onCreate: (name: string, description: string) => Promise<void>
    onDelete: (equipment_id: string) => Promise<void>
    onEdit: (
        id: string,
        name: string,
        description: string,
        softwareVersions: SoftwareVersions[]
    ) => Promise<void>
}

function EquipmentTiles({
    typeTitle,
    typeIcon,
    equipment,
    onCreate,
    onDelete,
    onEdit,
}: EquipmentTilesProps) {
    const [editingEquipment, setEditingEquipment] = useState<string | null>(null)
    const [equipmentName, setEquipmentName] = useState<string>('')
    const [equipmentDescription, setEquipmentDescription] = useState<string>('')
    const [equipmentSoftware, setEquipmentSoftware] = useState<SoftwareVersions[]>([])
    const [visibleSoftware, setVisibleSoftware] = useState<{ [key: string]: boolean }>({})

    const startEditing = (equipment: MachineData | ImplementData) => {
        setEditingEquipment(equipment.id)
        setEquipmentName(equipment.name)
        setEquipmentDescription(equipment.description || '')
        setEquipmentSoftware(equipment.softwareVersions)
    }

    const handleEditEquipment = async (id: string) => {
        await onEdit(id, equipmentName, equipmentDescription || '', equipmentSoftware)
        setEditingEquipment(null)
    }

    const cancelEditingEquipment = () => {
        setEditingEquipment(null)
    }

    const handleSoftwareVersionChange = (index: number, value: string) => {
        setEquipmentSoftware((prevSoftware) =>
            prevSoftware.map((software, i) =>
                i === index ? { ...software, version: value } : software
            )
        )
    }

    const toggleSoftwareVisibility = (id: string) => {
        setVisibleSoftware((prev) => ({ ...prev, [id]: !prev[id] }))
    }

    const RenderSoftware = (showSoftware: boolean, equipment: MachineData | ImplementData) => {
        return editingEquipment === equipment.id ? (
            <div className="software-version-editor">
                <span style={{ fontWeight: '600' }}>Software Versions</span>
                <div className="input-group">
                    {equipmentSoftware.map((version, index) => {
                        return (
                            <div key={index}>
                                <div>{version.name}: </div>
                                <input
                                    value={version.version}
                                    onChange={(e) =>
                                        handleSoftwareVersionChange(index, e.target.value)
                                    }
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        ) : showSoftware ? (
            <div>
                <span style={{ fontWeight: '600' }}>Software Versions</span>
                <div>
                    {equipment.softwareVersions.length > 0
                        ? equipment.softwareVersions.map((version, index) => {
                              return (
                                  <div key={index}>
                                      {version.name}: {version.version}
                                  </div>
                              )
                          })
                        : 'N/A'}
                </div>
            </div>
        ) : null
    }

    return (
        <>
            <header>{typeTitle} Garage</header>
            <div className="equipment-tiles">
                <CreationForm typeIcon={typeIcon} typeTitle={typeTitle} onCreate={onCreate} />

                {equipment.map((equipment) => (
                    <div key={equipment.id} className="equipment-tile">
                        <div className="tile-header">
                            <div className="title">
                                {editingEquipment === equipment.id ? (
                                    <input
                                        type="text"
                                        value={equipmentName}
                                        onChange={(e) => setEquipmentName(e.target.value)}
                                    />
                                ) : (
                                    equipment.name
                                )}
                            </div>
                            <div className="controls">
                                {editingEquipment === equipment.id ? (
                                    <>
                                        <button
                                            className="edit-button"
                                            title={`Save ${typeTitle}`}
                                            onClick={() => handleEditEquipment(equipment.id)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="edit-button"
                                            title={`Cancel ${typeTitle}`}
                                            onClick={() => cancelEditingEquipment()}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            onClick={() => toggleSoftwareVisibility(equipment.id)}
                                            className="edit-button"
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    visibleSoftware[equipment.id]
                                                        ? faMinimize
                                                        : faExpandAlt
                                                }
                                                title={
                                                    visibleSoftware[equipment.id]
                                                        ? 'Hide Software Versions'
                                                        : 'Show Software Versions'
                                                }
                                            />
                                        </button>
                                        <button
                                            className="edit-button"
                                            title={`Edit ${typeTitle}`}
                                            onClick={() => startEditing(equipment)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button
                                            className="delete-button"
                                            title={`Delete ${typeTitle}`}
                                            onClick={() => onDelete(equipment.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="equipment-body">
                            <div className="equipment-metadata">
                                <div>
                                    <div style={{ fontWeight: '600' }}>Description</div>
                                    {editingEquipment === equipment.id ? (
                                        <div className="text-area-wrapper">
                                            <textarea
                                                value={equipmentDescription}
                                                onChange={(e) =>
                                                    setEquipmentDescription(e.target.value)
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <span
                                            style={{
                                                fontWeight: '300',
                                                whiteSpace: 'pre-wrap',
                                            }}
                                        >
                                            {equipment.description ? equipment.description : 'N/A'}
                                        </span>
                                    )}
                                </div>
                                {RenderSoftware(visibleSoftware[equipment.id] || false, equipment)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export { EquipmentTiles }
