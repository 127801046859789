import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataState, SessionData, ErrorPayload, SnapshotData } from '../types'
import {
    sanitizeSessionData,
    sanitizeSessionsData,
    sanitizeSnapshotData,
} from '../../components/utils/dataSanitizer'
import { RootState } from '../store'

const initialState: DataState<SessionData> = {
    loading: false,
    data: [],
    error: {
        message: '',
    },
}

const sortSessionsByStartTime = (sessions: SessionData[]) => {
    return sessions.sort(
        (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    )
}

const sortSnapshotsByTimestamp = (snapshots: SnapshotData[]) => {
    try {
        return snapshots.sort(
            (a, b) =>
                new Date(a.initiationTimestamp).getTime() -
                new Date(b.initiationTimestamp).getTime()
        )
    } catch (error) {
        console.error(error)
        return snapshots
    }
}

const sessionsSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        // Start & Error handlers
        fetchDataRequest(state) {
            state.loading = true
            state.error.message = ''
        },
        fetchDataFailure(state, action: PayloadAction<ErrorPayload>) {
            state.loading = false
            state.error.message = action.payload.message
        },

        // Result handlers
        fetchSessionsSuccess(state, action: PayloadAction<SessionData[]>) {
            state.loading = false
            state.data = sortSessionsByStartTime(
                sanitizeSessionsData(action.payload).map((session) => ({
                    ...session,
                    snapshots: sortSnapshotsByTimestamp(session.snapshots),
                }))
            )
            state.error.message = ''
        },
        addSessionSuccess(state, action: PayloadAction<SessionData>) {
            state.loading = false
            const sessionExists = state.data.some((session) => session.id === action.payload.id)
            if (!sessionExists) {
                const newSession = {
                    ...sanitizeSessionData(action.payload),
                    snapshots: sortSnapshotsByTimestamp(action.payload.snapshots),
                }
                state.data = sortSessionsByStartTime([...state.data, newSession])
            }
            state.error.message = ''
        },
        editSessionSuccess(state, action: PayloadAction<SessionData>) {
            state.loading = false
            state.data = sortSessionsByStartTime(
                state.data.map((session) =>
                    session.id === action.payload.id
                        ? {
                              ...sanitizeSessionData(action.payload),
                              snapshots: sortSnapshotsByTimestamp(action.payload.snapshots),
                          }
                        : session
                )
            )
            state.error.message = ''
        },
        startSessionSuccess(state, action: PayloadAction<SessionData>) {
            state.loading = false
            state.data = sortSessionsByStartTime(
                state.data.map((session) =>
                    session.id === action.payload.id
                        ? {
                              ...sanitizeSessionData(action.payload),
                              snapshots: sortSnapshotsByTimestamp(action.payload.snapshots),
                          }
                        : session
                )
            )
            state.error.message = ''
        },
        stopSessionSuccess(state, action: PayloadAction<SessionData>) {
            state.loading = false
            state.data = sortSessionsByStartTime(
                state.data.map((session) =>
                    session.id === action.payload.id
                        ? {
                              ...sanitizeSessionData(action.payload),
                              snapshots: sortSnapshotsByTimestamp(action.payload.snapshots),
                          }
                        : session
                )
            )
            state.error.message = ''
        },
        resumeSessionSuccess(state, action: PayloadAction<SessionData>) {
            state.loading = false
            state.data = sortSessionsByStartTime(
                state.data.map((session) =>
                    session.id === action.payload.id
                        ? {
                              ...sanitizeSessionData(action.payload),
                              snapshots: sortSnapshotsByTimestamp(action.payload.snapshots),
                          }
                        : session
                )
            )
            state.error.message = ''
        },
        deleteSessionSuccess(state, action: PayloadAction<SessionData>) {
            state.loading = false
            state.data = state.data.filter((session) => session.id !== action.payload.id)
            state.error.message = ''
        },
        addSnapshotSuccess(state, action: PayloadAction<SnapshotData>) {
            state.loading = false
            state.data = state.data.map((session) => {
                if (action.payload.session && session.id === action.payload.session.id) {
                    const isDuplicate = session.snapshots.some(
                        (snapshot) => snapshot.id === action.payload.id
                    )
                    if (!isDuplicate) {
                        const updatedSnapshots = sortSnapshotsByTimestamp([
                            ...session.snapshots,
                            sanitizeSnapshotData(action.payload),
                        ])
                        return {
                            ...session,
                            snapshots: updatedSnapshots,
                        }
                    }
                }
                return session
            })
            state.error.message = ''
        },
        editSnapshotSuccess(state, action: PayloadAction<SnapshotData>) {
            state.loading = false
            state.data = state.data.map((session) => {
                if (action.payload.session && session.id === action.payload.session.id) {
                    const updatedSnapshots = sortSnapshotsByTimestamp(
                        session.snapshots.map((snapshot) =>
                            snapshot.id === action.payload.id
                                ? sanitizeSnapshotData(action.payload)
                                : snapshot
                        )
                    )
                    return {
                        ...session,
                        snapshots: updatedSnapshots,
                    }
                }
                return session
            })
            state.error.message = ''
        },
        deleteSnapshotSuccess(state, action: PayloadAction<SnapshotData>) {
            state.loading = false
            state.data = state.data.map((session) => {
                if (action.payload.session && session.id === action.payload.session.id) {
                    const updatedSnapshots = sortSnapshotsByTimestamp(
                        session.snapshots.filter((snapshot) => snapshot.id !== action.payload.id)
                    )
                    return {
                        ...session,
                        snapshots: updatedSnapshots,
                    }
                }
                return session
            })
            state.error.message = ''
        },
    },
})

export const selectActiveSession = (state: RootState) => {
    return state.sessions.data.find((session) => true === session.active)
}

export const {
    fetchDataRequest,
    fetchDataFailure,
    fetchSessionsSuccess,
    addSessionSuccess,
    editSessionSuccess,
    startSessionSuccess,
    stopSessionSuccess,
    resumeSessionSuccess,
    deleteSessionSuccess,
    addSnapshotSuccess,
    editSnapshotSuccess,
    deleteSnapshotSuccess,
} = sessionsSlice.actions
export default sessionsSlice.reducer
