import { SnapshotData, SoftwareVersions } from '../../../data/types'
import { useNetworkManager } from '../../../network/networkManager'
import { useSoftwareUpdater } from '../../utils/softwareUpdate'

export const useLogEvents = () => {
    const { sessions: sessionsAPI } = useNetworkManager()
    const { updateSoftwareVersion } = useSoftwareUpdater()

    const logData = async (
        sessionId: string,
        initiationTimestamp: string,
        data: Record<string, any>,
        classification: string
    ) => {
        if (sessionId) {
            try {
                if (Object.keys(data).length === 0) {
                    const placeholder = {
                        [classification]: {
                            placeholder: '<no input provided>',
                        },
                    }
                    await sessionsAPI.addSnapshot(sessionId, initiationTimestamp, placeholder)
                } else {
                    await sessionsAPI.addSnapshot(sessionId, initiationTimestamp, data)

                    classification = Object.keys(data)[0]
                    if ('update' === classification) {
                        await doSoftwareUpdate(sessionId, data[classification])
                    }
                }

                return true
            } catch (error) {
                console.error('Error logging data:', error)
            }
        } else {
            alert('No session ID provided')
        }
    }

    const editSnapshot = async (
        sessionId: string,
        snapshot: SnapshotData,
        data: Record<string, any>
    ) => {
        try {
            await sessionsAPI.editSnapshot(snapshot.id, data)

            const classification = Object.keys(data)[0]
            if ('update' === classification) {
                if (sessionId) {
                    await doSoftwareUpdate(sessionId, data[classification])
                } else {
                    alert('No session ID provided')
                }
            }

            return true
        } catch (error) {
            console.error('Error logging data:', error)
        }
    }

    async function doSoftwareUpdate(sessionId: string, updateData: Record<string, any>) {
        const key = Object.keys(updateData)[0]
        const softwareType = key === 'Implements' ? 'implement' : 'machine'
        const software = key ? Object.keys(updateData[key])[0] : undefined
        const version = key && software ? updateData[key][software]['Software Version'] : undefined
        const softwareVersion: SoftwareVersions = {
            name: software || 'N/A',
            version: version || 'N/A',
        }

        await updateSoftwareVersion(sessionId, softwareVersion, softwareType)
    }

    return { logData, editSnapshot }
}
