import { useState, ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

interface JobFormProps {
    typeTitle: string
    typeIcon: IconDefinition
    onCreate: (name: string, description: string) => void
}

function CreationForm({ typeTitle, typeIcon, onCreate }: JobFormProps) {
    const [name, setname] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [isFormVisible, setIsFormVisible] = useState<boolean>(false)

    const handlenameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setname(e.target.value)
    }

    const handleMachineDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value)
    }

    const handleCreateMachine = () => {
        onCreate(name, description)
        setname('')
        setDescription('')
        toggleFormVisibility()
    }

    const toggleFormVisibility = () => {
        setIsFormVisible((prev) => !prev)
    }

    return (
        <div className={`equipment-tile  ${!isFormVisible ? 'add-equipment' : ''}`}>
            <div className={`equipments-form ${!isFormVisible ? 'closed' : ''}`}>
                {isFormVisible ? (
                    <div className="form-wrapper">
                        <div className="form">
                            <div className="name-wrapper">
                                <label>{typeTitle} Name:</label>
                                <input type="text" value={name} onChange={handlenameChange} />
                            </div>
                            <div className="description-wrapper">
                                <label>{typeTitle} Description:</label>
                                <textarea
                                    value={description}
                                    onChange={handleMachineDescriptionChange}
                                />
                            </div>
                            <div className="buttons">
                                <button onClick={handleCreateMachine}>Create {typeTitle}</button>
                                <button onClick={toggleFormVisibility}>Cancel</button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <button onClick={toggleFormVisibility} className="toggle-form-button">
                        <FontAwesomeIcon icon={faPlus} />
                        <FontAwesomeIcon icon={typeIcon} />
                    </button>
                )}
            </div>
        </div>
    )
}

export { CreationForm }
