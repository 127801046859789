import '../recorder.css'

import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { SessionData } from '../../../data/types'
import SnapshotManager from '../snapshotManagers/snapshotManager'
import SessionTile from '../../recordedSessions/sessionTile'
import { toast } from 'react-toastify'
import { Toolbar } from '../../toolbar/toolbar'

interface EngineeringViewProps {
    sessionId: string
    isSessionActive: boolean
    isSessionOwner: boolean
    activeSession: SessionData | null
    previousSession: SessionData | null
    isSimpleViewEnabled: boolean
    handleSimpleViewSetEnabled: (enabled: boolean) => void
}

const EngineeringView = ({
    sessionId,
    isSessionActive,
    isSessionOwner,
    activeSession,
    previousSession,
    isSimpleViewEnabled,
    handleSimpleViewSetEnabled,
}: EngineeringViewProps) => {
    const location = useLocation()

    const [isDisplayEventList, setIsDisplayEventList] = useState<boolean>(true)
    const [isLocalTime, setIsLocalTime] = useState<boolean>(false)
    const [scrollToEvent, setScrollToEvent] = useState('')
    const [isDescendingEventOrder, setIsDescendingEventOrder] = useState<boolean>(true)

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const eventId = params.get('scrollToEvent')
        setScrollToEvent(eventId || '')
    }, [location.search])

    const notifyStopSuccess = () =>
        toast.success('Session Saved!', {
            position: 'top-right',
            autoClose: 2000, // time in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    const notifyEventReported = (classification: string) =>
        toast.success(`${classification} reported!`, {
            position: 'top-right',
            autoClose: 2000, // time in milliseconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: { fontSize: '18px' },
        })

    return (
        <div className="field-recorder-wrapper">
            <div className="field-recorder">
                <SnapshotManager
                    notifyEventReported={notifyEventReported}
                    sessionId={sessionId}
                    notifyStopSuccess={notifyStopSuccess}
                    isSessionActive={isSessionActive}
                    isPreviousSessionSet={previousSession === null}
                />
                {(activeSession || previousSession) && (
                    <div className="recorder-prev-session-wrapper">
                        <SessionTile
                            session={activeSession || previousSession}
                            enableDuplicate={true}
                            displayEventList={isDisplayEventList}
                            isUtc={!isLocalTime}
                            isDescending={isDescendingEventOrder}
                            scrollToEvent={scrollToEvent}
                            showSessionTools={false}
                            customComponent={
                                <Toolbar
                                    tools={[
                                        {
                                            label: 'Event List',
                                            data: {
                                                enabled: isDisplayEventList,
                                                setEnabled: setIsDisplayEventList,
                                            },
                                        },
                                        {
                                            label: 'Descending Event Order',
                                            data: {
                                                enabled: isDescendingEventOrder,
                                                setEnabled: setIsDescendingEventOrder,
                                            },
                                        },
                                        {
                                            label: 'Local time',
                                            data: {
                                                enabled: isLocalTime,
                                                setEnabled: setIsLocalTime,
                                            },
                                        },
                                        {
                                            label: 'Simple View',
                                            data: {
                                                enabled: isSimpleViewEnabled,
                                                setEnabled: handleSimpleViewSetEnabled,
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default EngineeringView
