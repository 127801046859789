import React, { ReactNode, useEffect, useState } from 'react'
import { ImplementData, MachineData, SessionData } from '../../data/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../data/store'
import { FormatDate, TextWrapper } from '../utils/misc'
import { useSessionDataHook } from '../helpers/sessionDataHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

interface SessionMetadataProps {
    session: SessionData
    isAttributions?: boolean
    isUtc: boolean
    header?: ReactNode
    disableMargin?: boolean
}

const SessionMetadata: React.FC<SessionMetadataProps> = ({
    session,
    isAttributions = true,
    isUtc,
    header,
    disableMargin,
}) => {
    const { currentSoftware } = useSessionDataHook(session.id)

    const machineListData = useSelector((state: RootState) => state.machines.data)
    const implementsListData = useSelector((state: RootState) => state._implements.data)

    const [machine, setMachine] = useState<MachineData | null>(null)
    const [implement, setImplement] = useState<ImplementData | null>(null)

    useEffect(() => {
        const foundMachine = machineListData.find((machine) => machine.id === session.machine?.id)
        setMachine(foundMachine || null)
    }, [session, machineListData])

    useEffect(() => {
        const foundImplement = implementsListData.find(
            (implement) => implement.id === session.implement?.id
        )
        setImplement(foundImplement || null)
    }, [session, implementsListData])

    const renderSoftwareVersions = (
        label: string,
        initialVersions: any,
        currentFinalVersions: any
    ) => {
        // Combine initialVersions and currentFinalVersions, mapping missing initial versions to 'N/A'
        const combinedVersions =
            currentFinalVersions?.map((currentSoftware: any) => {
                const initialSoftware = initialVersions?.find(
                    (software: any) => software.name === currentSoftware.name
                )
                return initialSoftware || { name: currentSoftware.name, version: 'N/A' }
            }) ||
            initialVersions ||
            []

        return (
            <div>
                <div className="label">
                    <TextWrapper text={label} charLimit={40} />
                </div>
                {combinedVersions.length > 0 ? (
                    combinedVersions.map((initialSoftware: any, index: number) => {
                        const matchingSoftware = currentFinalVersions?.find(
                            (software: any) => software.name === initialSoftware.name
                        )
                        const softwareVersion = matchingSoftware
                            ? matchingSoftware.version
                            : initialSoftware.version
                        const hasChanged =
                            matchingSoftware && matchingSoftware.version !== initialSoftware.version
                        return (
                            <div key={index}>
                                <div style={{ fontWeight: '400', marginRight: '5px' }}>
                                    <TextWrapper text={initialSoftware.name} charLimit={40} />
                                </div>
                                <div style={{ fontWeight: '200', textAlign: 'center' }}>
                                    <TextWrapper text={initialSoftware.version} charLimit={40} />
                                    {hasChanged && (
                                        <>
                                            <FontAwesomeIcon icon={faArrowDown} />
                                            <TextWrapper text={softwareVersion} charLimit={40} />
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div>
                        <TextWrapper text={'N/A'} charLimit={40} />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={`metadata ${disableMargin && 'override-margin'}`}>
            <div className="primary">
                {header}
                <div>
                    <span style={{ fontWeight: '400' }}>Vehicle:</span>
                    <TextWrapper text={`${machine?.name}`} charLimit={40} />
                </div>
                <div>
                    <span style={{ fontWeight: '400' }}>Implement:</span>
                    <TextWrapper text={`${implement?.name}`} charLimit={40} />
                </div>
                {isAttributions && (
                    <div>
                        <span style={{ fontWeight: '400' }}>Start by:</span>
                        <TextWrapper
                            text={`${session.owner.given_name} ${session.owner.family_name}`}
                            charLimit={40}
                        />
                    </div>
                )}
            </div>
            <div className="recorder-software-versions">
                <div className="group">
                    <div className="versions">
                        {renderSoftwareVersions(
                            'Vehicle Software:',
                            session.softwareVersions?.machines,
                            session.active
                                ? currentSoftware?.machines
                                : session.finalSoftwareVersions?.machines
                        )}
                        {renderSoftwareVersions(
                            'Implement Software:',
                            session.softwareVersions?._implements,
                            session.active
                                ? currentSoftware?._implements
                                : session.finalSoftwareVersions?._implements
                        )}
                    </div>
                </div>
            </div>
            <div>
                <span>Start/Stop Time</span>
                <span>{FormatDate(session.startTime, isUtc)}</span>
                <span>{FormatDate(session.stopTime, isUtc)}</span>
            </div>
        </div>
    )
}

export default SessionMetadata
