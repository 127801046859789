import React, { useRef } from 'react'
import './slider.css'

interface SliderProps {
    enabled: boolean
    setEnabled?: (enabled: boolean) => void
    enabledText?: string
    disableText?: string
}

const Slider: React.FC<SliderProps> = ({ enabled, setEnabled, enabledText, disableText }) => {
    const touchStartTarget = useRef<EventTarget | null>(null) // Track the touchstart target

    const handleInputChange = () => {
        setEnabled && setEnabled(!enabled)
    }

    const handleTouchStart = (event: React.TouchEvent) => {
        touchStartTarget.current = event.target // Store the target of the touchstart
    }

    const handleTouchEnd = (event: React.TouchEvent) => {
        event.preventDefault() // Prevent default touch behavior

        // Only toggle if the touchend target matches the touchstart target
        if (touchStartTarget.current === event.target) {
            handleInputChange()
        }

        touchStartTarget.current = null // Reset the target
    }

    return (
        <div className="slider-parent">
            <div
                className={`slider-wrapper ${enabled ? 'enabled' : 'disabled'}`}
                onClick={handleInputChange}
                onTouchStart={handleTouchStart} // Track touchstart
                onTouchEnd={handleTouchEnd} // Validate touchend
            >
                {enabled && enabledText}
                <div className="slider-circle"></div>
                {!enabled && disableText}
            </div>
        </div>
    )
}

export { Slider }
