import { FC, ReactNode, useEffect } from 'react'
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'
import { Outlet } from 'react-router-dom'
import { getAuthParams } from '../components/utils/authStore'

interface AuthProviderProps {
    children: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const { code_challenge, state, nonce } = getAuthParams()

    const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''

    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''

    const redirectURI = process.env.REACT_APP_FRONTEND_SERVER_URL || ''

    const scope = 'openid profile email'

    const audience = process.env.REACT_APP_AUDIENCE || ''

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectURI,
                scope: scope,
                response_type: 'code',
                audience: audience,
                response_mode: 'web_message',
                state: state,
                nonce: nonce,
                code_challenge: code_challenge,
                code_challenge_method: 'S256',
            }}
        >
            {children}
        </Auth0Provider>
    )
}

export const ProtectedRoute: FC = () => {
    const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } =
        useAuth0()

    const getCurrentPathAndSearch = () => {
        return {
            path: window.location.pathname,
            search: window.location.search,
        }
    }

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            const { path, search } = getCurrentPathAndSearch()
            if (search) {
                localStorage.setItem('searchParams', search)
            }
            localStorage.setItem('savedPath', path)
            loginWithRedirect()
        }
    }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user])

    if (isLoading) {
        return <div>Loading...</div>
    }

    const AuthenticatedOutlet = withAuthenticationRequired(Outlet, {
        onRedirecting: () => <div>Loading...</div>,
    })

    return <AuthenticatedOutlet />
}
