import './optionTree.css'

import { Fragment, useCallback, useEffect, useState } from 'react'

interface OptionTreeProps {
    options: Record<string, any>
    defaultOptions?: Record<string, any>
    onChange?: (selectedOptions: Record<string, any>) => void
    disabled?: boolean
}

const OptionTree = ({ options, defaultOptions, onChange, disabled }: OptionTreeProps) => {
    type OptionSelection = {
        options: Record<string, any>
        selectedOption: string
    }
    const [optionSelections, setOptionSelections] = useState<OptionSelection[]>([])

    const updateOptionSelections = useCallback(
        (newOptionSelections: OptionSelection[]) => {
            // update state
            setOptionSelections(newOptionSelections)

            // construct the selectedOptions as the path of each of the selected options
            let selectedOptions: Record<string, any> = {}
            let selectedOption = selectedOptions
            for (const optionSelection of newOptionSelections) {
                // stop if we reach a depth without a selected option
                if (optionSelection.selectedOption === '') {
                    break
                }
                selectedOption[optionSelection.selectedOption] = {}
                selectedOption = selectedOption[optionSelection.selectedOption]
            }

            // notify parent that the selectedOptions changed
            onChange && onChange(selectedOptions)
        },
        [onChange]
    )

    // make a shallow copy of node options that includes only the filtered properties
    const filterNodeOptions = useCallback(
        (nodeOptions: Record<string, any>): Record<string, any> => {
            let filteredOptions: Record<string, any> = {}

            // make sure it's an object (not a string)
            if (typeof nodeOptions === 'object') {
                const filteredOptionEntries = Object.entries(nodeOptions).filter(
                    ([key]) => !key.startsWith('__') // keys starting with '__' are not options
                )
                for (const [key, value] of filteredOptionEntries) {
                    filteredOptions[key] = value
                }
            }

            return filteredOptions
        },
        []
    )

    useEffect(() => {
        let newOptionSelections: OptionSelection[] = []

        let nodeOptions = filterNodeOptions(options)
        if (Object.keys(nodeOptions).length > 0) {
            // add an option selection for the root options
            newOptionSelections.push({ options: nodeOptions, selectedOption: '' })

            if (defaultOptions) {
                // select the default root node
                let selectedOption = defaultOptions
                let selectedOptionKey = Object.keys(selectedOption)[0]
                while (selectedOptionKey) {
                    // check if the default option is a valid option
                    nodeOptions = nodeOptions[selectedOptionKey]
                    if (undefined !== nodeOptions) {
                        // select the option by default
                        newOptionSelections[newOptionSelections.length - 1].selectedOption =
                            selectedOptionKey
                        // add an option selection for the selected option if it has child options
                        nodeOptions = filterNodeOptions(nodeOptions)
                        if (Object.keys(nodeOptions).length > 0) {
                            newOptionSelections.push({
                                options: nodeOptions,
                                selectedOption: '',
                            })

                            // select the next node
                            selectedOption = selectedOption[selectedOptionKey]
                            selectedOptionKey = Object.keys(selectedOption)[0]
                        } else {
                            // no child options
                            selectedOptionKey = '' // exit the loop
                        }
                    } else {
                        // option not found
                        selectedOptionKey = '' // exit the loop
                    }
                }
            } else {
                if (Object.keys(nodeOptions).length === 1) {
                    const optionKey = Object.keys(nodeOptions)[0]
                    if (optionKey) {
                        // select the only root option by default
                        newOptionSelections[0].selectedOption = optionKey

                        // add an option selection for the selected option if it has child options
                        nodeOptions = filterNodeOptions(nodeOptions[optionKey])
                        if (Object.keys(nodeOptions).length > 0) {
                            newOptionSelections.push({
                                options: nodeOptions,
                                selectedOption: '',
                            })
                        }
                    }
                }
            }
        }

        updateOptionSelections(newOptionSelections)
    }, [options, defaultOptions, updateOptionSelections, filterNodeOptions])

    const handleOptionSelectionClick = (depth: number, key: string, value: any) => {
        // record the selected option
        const selection: OptionSelection = optionSelections[depth]
        selection.selectedOption = key

        // copy the option selections to update state and truncate the array to the selected depth
        let newOptionSelections = [...optionSelections]
        newOptionSelections.length = depth + 1

        // add an option selection for the selected option if it has child options
        const nodeOptions = filterNodeOptions(selection.options[key])
        if (Object.keys(nodeOptions).length > 0) {
            newOptionSelections.push({
                options: nodeOptions,
                selectedOption: '',
            })
        }

        updateOptionSelections(newOptionSelections)
    }

    const OptionButtons = (
        depth: number,
        options: Record<string, any>,
        selectedKey?: string,
        onClick?: (depth: number, key: string, value: any) => void
    ) => {
        return (
            Object.keys(options).length > 0 && (
                <div className="option-buttons">
                    {Object.entries(options).map(([key, value]) => {
                        return (
                            <button
                                key={key}
                                onClick={(e) => onClick && onClick(depth, key, value)}
                                disabled={disabled}
                                className={`icon-button option-button ${
                                    key === selectedKey ? 'highlight' : ''
                                }`}
                            >
                                {key}
                            </button>
                        )
                    })}
                </div>
            )
        )
    }

    return (
        <>
            {optionSelections.length > 0 && (
                <div className="option-selections">
                    {optionSelections.map((optionSelection, depth) => (
                        <Fragment key={depth}>
                            <div className="divider">
                                <span></span>
                            </div>
                            {OptionButtons(
                                depth,
                                optionSelection.options,
                                optionSelection.selectedOption,
                                handleOptionSelectionClick
                            )}
                        </Fragment>
                    ))}
                </div>
            )}
        </>
    )
}

export { OptionTree }
